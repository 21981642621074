import React, { useState, createRef } from "react";
import { buildClasses } from "@nodeme/jss-react";
import classNames from "classnames";
const style = (theme) => (definitions) => {
    const defaults = definitions.nodeMeForms;
    return {
        formControl: defaults.formControl,
        label: defaults.label,
        inlineLabel: defaults.inlineLabel,
        closedLabel: defaults.closedLabel,
        inlineLabelOpen: defaults.inlineLabelOpen,
        inputHolder: defaults.inputHolder,
        input: defaults.input,
        inputReadOnly: defaults.inputReadOnly,
        textarea: defaults.textarea,
        inputError: defaults.inputError,
        helpBlock: defaults.helpBlock,
        hasError: defaults.hasError,
    };
};
const Text = (props) => {
    const { label, readOnly = false, open = false, inline = false, value, onChange, error, rows = false, type, info, autoComplete = "off", override = {}, } = props;
    const [isFocused, setFocuse] = useState(false);
    const classes = buildClasses(style);
    const textAreaRef = createRef();
    const inputRef = createRef();
    const hasInfo = Boolean(info);
    const hasError = Boolean(error);
    return (React.createElement("div", { className: classes.formControl, onClick: () => {
            if (props.onClick)
                props.onClick();
        } },
        React.createElement("label", { htmlFor: label, className: classNames([
                inline ? classes.inlineLabel : classes.label,
                !readOnly &&
                    !inline &&
                    !open &&
                    !isFocused &&
                    !Boolean(value) &&
                    classes.closedLabel,
                inline && Boolean(value) && classes.inlineLabelOpen,
                hasError && classes.hasError,
            ]), onClick: (e) => {
                if (!isFocused && textAreaRef && textAreaRef.current) {
                    textAreaRef.current.focus();
                }
                if (!isFocused && inputRef && inputRef.current) {
                    inputRef.current.focus();
                }
            } }, label),
        React.createElement("div", { className: classNames([!inline && classes.inputHolder]) },
            Boolean(rows) && (React.createElement("textarea", { rows: rows || 1, className: classNames([
                    classes.textarea,
                    hasError && classes.inputError,
                    override.input,
                ]), name: label, ref: textAreaRef, value: value || "", onChange: (e) => {
                    if (readOnly || !onChange)
                        return;
                    else if (typeof e.target.value === "string" &&
                        e.target.value !== "")
                        onChange(e.target.value);
                    else
                        onChange();
                }, onFocus: (e) => setFocuse(true), onBlur: (e) => setFocuse(false) })),
            !Boolean(rows) && (React.createElement("input", { className: classNames([
                    classes.input,
                    readOnly && classes.inputReadOnly,
                    hasError && classes.inputError,
                    override.input,
                ]), name: label, ref: inputRef, value: readOnly && value === "" ? "-" : value || "", type: type || "text", autoComplete: autoComplete, onChange: (e) => {
                    if (readOnly || !onChange)
                        return;
                    else if (typeof e.target.value === "string" &&
                        e.target.value !== "")
                        onChange(e.target.value);
                    else
                        onChange();
                }, onFocus: (e) => setFocuse(true), onBlur: (e) => setFocuse(false) })),
            hasInfo && (React.createElement("span", { className: classNames([classes.helpBlock]) }, info)),
            hasError && (React.createElement("span", { className: classNames([classes.helpBlock, classes.hasError]) }, error)))));
};
export default Text;
