import React from "react";
import { Grid } from "@nodeme/grid-react";
import PendingBlock from "./Shared/PendingBlock";
import Artwork, { IProps as IArtwork } from "./Artwork";
import ErrorBlock from "./Shared/ErrorBlock";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCandyCane } from "@fortawesome/pro-light-svg-icons/faCandyCane";
import { faTreeChristmas } from "@fortawesome/pro-light-svg-icons/faTreeChristmas";
import {
  withClasses,
  IProps as IPropsWithClasses,
  ITheme,
  IDefinitions,
} from "@nodeme/jss-react";
import Button from "@nodeme/forms-react/lib/Components/Button";
import { Form } from "@nodeme/forms-react";
import classNames from "classnames";
import Block from "./Shared/Block";

export const categories = [
  "Gemälde",
  "Grafik Multiple",
  "Keramiken",
  "Multimedia",
  "Skulptur Volumen",
  "Zeichnung/Aquarell",
  "Antiquitäten",
  "Architektonische Elemente",
  "Design",
  "Fotografie",
  "Gegenstände",
  "Keramiken-Glas",
  "Lampen",
  "Miniatur",
  "Mobiliar",
  "Performing Art",
  "Teppiche",
];

const days = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
];

const styles = (theme: ITheme) => (definition: IDefinitions) => ({
  wrapper: {
    padding: "68px 0px 0px 0px",
    [theme.breakpoints.down("md")]: {
      padding: "16px 0px 0px 0px",
    },
  },
  door: {
    backgroundSize: "cover",
    backgroundPosition: "center",
    paddingBottom: "74.2822966507177%",
  },
  activeDoor: {
    cursor: "pointer",
  },
  lable: {
    display: "block",
    width: "50px",
    color: "#fff",
    fontSize: "2.8rem",
    position: "absolute",
    left: "30px",
    top: "34px",
    [theme.breakpoints.down("md")]: {
      left: "calc(50% - 1.8rem)",
    },
  },
  iconHolder: {
    position: "relative",
    display: "inline-block",
  },
  noIcon: {
    backgroundColor: theme.palette.get("lightGrey"),
    color: theme.palette.get("grey"),
    fontSize: "8rem",
    padding: "26px 28px",
    borderRadius: "8rem",
    marginBottom: "16px",
  },
  overlayIcon: {
    position: "absolute",
    top: "0",
    left: "0",
    fontSize: "8rem",
    color: theme.palette.get("grey"),
  },
  alignCenter: { textAlign: "center", fontSize: "1.1rem" },
});

export interface IProps {}
export interface IState {
  data: IArtwork[];
  pending: boolean;
  unknownError: boolean;
  open: number | null;
  active: number | null;
}

class Calendar extends React.Component<IPropsWithClasses<IProps>, IState> {
  MOUNT = false;
  constructor(props: IPropsWithClasses<IProps>) {
    super(props);
    this.state = {
      data: [],
      pending: false,
      unknownError: false,
      open: null,
      active: null,
    };
    this.load = this.load.bind(this);
    this.toggleActive = this.toggleActive.bind(this);
    this.toggleActiveWork = this.toggleActiveWork.bind(this);
  }
  componentDidMount() {
    this.MOUNT = true;
    this.load();
  }
  componentWillUnmount() {
    this.MOUNT = false;
  }
  async load() {
    this.setState({ ...this.state, pending: true });
    try {
      const received = await fetch(
        "https://artware2.datenbanker.at/api/artwork/public/adv2020"
      ).then((response) => response.json());
      let data: IState["data"] = received.map((item: any) => {
        return {
          id: item.data.id,
          artist: item.data.name,
          title: item.data.title,
          category: categories[item.data.category - 1],
          technique: item.data.techniqueName,
          artistDescription: item.data.artistDescription,
          description: (item.data.description || "").split(" #")[1] || "",
          active: false,
          width:
            typeof item.data.width === "string" ||
            typeof item.data.width === "number"
              ? parseInt(item.data.width)
              : undefined,
          height:
            typeof item.data.height === "string" ||
            typeof item.data.height === "number"
              ? parseInt(item.data.height)
              : undefined,
          depth:
            typeof item.data.depth === "string" ||
            typeof item.data.depth === "number"
              ? parseInt(item.data.depth)
              : undefined,
          price: item.data.sellingPrice,
          thumbExtension: item.data.originalThumbExtension,
          thumbHeight: item.data.originalThumbHeight,
          thumbId: item.data.originalThumbId,
          thumbName: item.data.originalThumbName,
          thumbUpdateDate: item.data.originalThumbUpdateDate,
          thumbWidth: item.data.originalThumbWidth,
          year: item.data.year,
          bid: item.data.rentalPrice,
          lot: parseInt((item.data.description || "").split(" #")[0]) || 0,
          available: item.data.available,
        };
      });
      if (this.MOUNT) this.setState({ ...this.state, data, pending: false });
    } catch (error) {
      if (this.MOUNT)
        this.setState({ ...this.state, pending: false, unknownError: true });
    }
  }
  toggleActive(day?: number) {
    const newState = { ...this.state, open: day || null, active: null };
    this.setState(newState);
  }
  toggleActiveWork(id?: number) {
    const newState = { ...this.state, active: id || null };
    this.setState(newState);
  }
  render() {
    const { classes } = this.props;
    const currentDay = 24;
    const currentMonth = new Date().getMonth();

    if (this.state.unknownError)
      return <ErrorBlock text="Werke werden geladen..." />;
    if (this.state.pending)
      return <PendingBlock text="Werke werden geladen..." />;
    return (
      <div className={classes.wrapper}>
        <Grid spacing={16} vertical>
          {this.state.open === null &&
            days.map((day, index: number) => (
              <Grid key={"day-" + day} item xs={10 / 3} md={2} lg={10 / 6}>
                <div
                  style={{
                    backgroundImage: `url("images/${index + 1}${
                      index + 1 === 11 ? "_active" : ""
                    }.jpg")`,
                  }}
                  className={classNames([
                    classes.door,
                    index < currentDay && classes.activeDoor,
                  ])}
                  onClick={() => {
                    this.toggleActive(index + 1);
                  }}
                ></div>
              </Grid>
            ))}
          {this.state.open !== null && (
            <Grid item>
              {this.state.open <= currentDay && (
                <Form
                  spacing={16}
                  root={{ left: 0, right: 0 }}
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <Button
                    item
                    xs="120px"
                    onClick={(e) => {
                      this.toggleActive();
                    }}
                  >
                    <FontAwesomeIcon icon={faTreeChristmas} /> Zurück
                  </Button>
                  {this.state.open === currentDay && currentMonth === 11 && (
                    <Grid item>
                      <p style={{ color: "#d4af37" }}>
                        Der Preis dieser Werke reduziert sich zwischen 09:00 und
                        21:00 stündlich um 5%. First come, first served!
                      </p>
                    </Grid>
                  )}
                </Form>
              )}
              <Grid
                spacing={16}
                vertical
                root={{ top: 0, left: 0, right: 0 }}
                justify="center"
              >
                {this.state.open <= currentDay &&
                  currentMonth === 11 &&
                  this.state.data
                    .filter(
                      (item) =>
                        item.lot === this.state.open &&
                        (item.id === this.state.active ||
                          this.state.active === null)
                    )
                    .map((artwork) => (
                      <Grid
                        key={"artwork-" + artwork.id}
                        item
                        md={5}
                        lg={this.state.active === null ? 10 / 3 : 10}
                      >
                        <Artwork
                          available={artwork.available}
                          active={this.state.active !== null}
                          id={artwork.id}
                          currentDay={currentDay}
                          lot={artwork.lot}
                          artist={artwork.artist}
                          title={artwork.title}
                          category={artwork.category}
                          technique={artwork.technique}
                          width={artwork.width}
                          height={artwork.height}
                          depth={artwork.depth}
                          thumbExtension={artwork.thumbExtension}
                          thumbHeight={artwork.thumbHeight}
                          thumbWidth={artwork.thumbWidth}
                          thumbId={artwork.thumbId}
                          thumbName={artwork.thumbName}
                          thumbUpdateDate={artwork.thumbUpdateDate}
                          description={artwork.description}
                          artistDescription={artwork.artistDescription}
                          year={artwork.year}
                          price={artwork.price}
                          bid={artwork.bid}
                          toggle={this.toggleActiveWork}
                        />
                      </Grid>
                    ))}
                {this.state.open > currentDay && (
                  <Grid item md={5}>
                    <Block>
                      <div className={classes.alignCenter}>
                        <span className={classes.iconHolder}>
                          <FontAwesomeIcon
                            className={classes.noIcon}
                            icon={faCandyCane}
                          />
                        </span>
                        <div>
                          Für dieses Türchen müssen wir noch ein bischen
                          warten...
                        </div>
                        <Form
                          spacing={16}
                          root={{ left: 0, right: 0 }}
                          onSubmit={(e) => {
                            e.preventDefault();
                          }}
                        >
                          <Grid
                            justify="center"
                            spacing={16}
                            vertical
                            root={{ bottom: 0, left: 0, right: 0 }}
                          >
                            <Button
                              item
                              xs="120px"
                              onClick={(e) => {
                                this.toggleActive();
                              }}
                            >
                              Zurück
                            </Button>
                          </Grid>
                        </Form>
                      </div>
                    </Block>
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      </div>
    );
  }
}

export default withClasses(styles, Calendar);
