import React, { useState } from "react";
import { Grid } from "@nodeme/grid-react";
import { buildClasses } from "@nodeme/jss-react";
import classNames from "classnames";
const style = (theme) => (definitions) => {
    const defaults = definitions.nodeMeForms;
    return {
        formControl: defaults.formControl,
        label: defaults.label,
        inlineLabel: defaults.inlineLabel,
        closedLabel: defaults.closedLabel,
        inlineLabelOpen: defaults.inlineLabelOpen,
        inputHolder: defaults.inputHolder,
        input: defaults.input,
        dateInput: defaults.dateInput,
        dateInputShow: defaults.dateInputShow,
        inputError: defaults.inputError,
        helpBlock: defaults.helpBlock,
        hasError: defaults.hasError,
    };
};
const Dates = (props) => {
    const { label, inline, readOnly, open, day, month, year, onChange, autoComplete = "off", error, } = props;
    const classes = buildClasses(style);
    const [isFocused, setFocus] = useState(false);
    const [dayRef, monthRef, yearRef, inputTogglerRef] = [
        React.createRef(),
        React.createRef(),
        React.createRef(),
        React.createRef(),
    ];
    const hasError = Boolean(error);
    const hasValue = Boolean("" + day + month + year);
    return (React.createElement("div", { className: classes.formControl },
        (!inline || !isFocused) && (React.createElement("label", { className: classNames([
                inline ? classes.inlineLabel : classes.label,
                !inline && !open && !isFocused && !hasValue && classes.closedLabel,
                inline && (isFocused || hasValue) && classes.inlineLabelOpen,
                hasError && classes.hasError,
            ]) }, label)),
        React.createElement("div", { className: classNames([!inline && classes.inputHolder]) },
            !isFocused && (React.createElement("input", { style: { display: isFocused ? "none" : "block" }, className: classNames([
                    classes.input,
                    hasError && classes.inputError,
                ]), readOnly: true, value: hasValue ? [day, month, year].join(".") : "", onFocus: () => {
                    setFocus(true);
                    if (inputTogglerRef && inputTogglerRef.current)
                        inputTogglerRef.current.style.display = "block";
                    if (dayRef.current && monthRef.current && yearRef.current)
                        if (!day)
                            dayRef.current.focus();
                        else if (!month)
                            monthRef.current.focus();
                        else
                            yearRef.current.focus();
                } })),
            React.createElement("div", { ref: inputTogglerRef, style: { display: isFocused ? "block" : "none" } },
                React.createElement(Grid, { spacing: 0 },
                    React.createElement(Grid, { item: true, xs: 3 },
                        React.createElement("div", { style: { padding: `0px 4px 0px 0px` } },
                            React.createElement("input", { ref: dayRef, className: classNames([classes.input]), style: { margin: "0px 4px 0px 0px" }, type: "number", placeholder: "DD", value: day, onChange: (e) => {
                                    if (!readOnly && onChange) {
                                        if (e.target.value.length < 3)
                                            return onChange(e.target.value, month, year);
                                        if (monthRef.current)
                                            monthRef.current.focus();
                                        onChange(day, e.target.value.slice(-1), year);
                                    }
                                }, onFocus: () => {
                                    if (!isFocused)
                                        setFocus(true);
                                }, onBlur: (e) => {
                                    if (e.relatedTarget !== dayRef.current &&
                                        e.relatedTarget !== monthRef.current &&
                                        e.relatedTarget !== yearRef.current)
                                        setFocus(false);
                                } }))),
                    React.createElement(Grid, { item: true, xs: 3 },
                        React.createElement("div", { style: { padding: `0px 4px` } },
                            React.createElement("input", { ref: monthRef, className: classNames([classes.input]), placeholder: "MM", type: "number", value: month, onChange: (e) => {
                                    if (!readOnly && onChange) {
                                        if (e.target.value.length < 3)
                                            return onChange(day, e.target.value, year);
                                        if (yearRef.current)
                                            yearRef.current.focus();
                                        onChange(day, month, e.target.value.slice(-1));
                                    }
                                }, onKeyDown: (e) => {
                                    if (!month && dayRef.current && e.key !== "Tab")
                                        return dayRef.current.focus();
                                }, onFocus: () => {
                                    if (!isFocused)
                                        setFocus(true);
                                }, onBlur: (e) => {
                                    if (e.relatedTarget !== dayRef.current &&
                                        e.relatedTarget !== monthRef.current &&
                                        e.relatedTarget !== yearRef.current)
                                        setFocus(false);
                                } }))),
                    React.createElement(Grid, { item: true, xs: 4 },
                        React.createElement("div", { style: { padding: `0px 0px 0px 4px` } },
                            React.createElement("input", { ref: yearRef, className: classNames([classes.input]), placeholder: "YYYY", type: "number", value: year, autoComplete: autoComplete, onChange: (e) => {
                                    if (!readOnly && onChange) {
                                        if (e.target.value.length < 5)
                                            onChange(day, month, e.target.value);
                                    }
                                }, onKeyDown: (e) => {
                                    if (!year && monthRef.current)
                                        return monthRef.current.focus();
                                }, onFocus: () => {
                                    if (!isFocused)
                                        setFocus(true);
                                }, onBlur: (e) => {
                                    if (e.relatedTarget !== dayRef.current &&
                                        e.relatedTarget !== monthRef.current &&
                                        e.relatedTarget !== yearRef.current)
                                        setFocus(false);
                                } })))))),
        hasError && (React.createElement("span", { className: classNames([classes.helpBlock, classes.hasError]) }, error))));
};
export default React.memo(Dates, (oldProps, newProps) => {
    return !(oldProps.error !== newProps.error ||
        oldProps.day !== newProps.day ||
        oldProps.month !== newProps.month ||
        oldProps.year !== newProps.year ||
        oldProps.onChange + "" !== newProps.onChange + "" ||
        oldProps.label !== newProps.label);
});
